<template>
  <div>
    <article>
      <h1 class="center">金融借贷相关公司说明</h1>
      <section>
        <p>产品名称：诚星借</p>
        <p>主体公司：重庆小米消费金融有限公司</p>
        <!-- <p>金融许可证：</p>
        <img class="licence" src="@/assets/images/financial-lending/licence.png" alt="无数据" /> -->
      </section>
    </article>
  </div>
</template>

<script>
  export default {
    name: 'ADPrivacyProtocol',
    props: {},
    data() {
      return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
  };
</script>

<style scoped>
  body {
    font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
      sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    /*padding-left: constant(safe-area-inset-left);*/
    /*padding-left: env(safe-area-inset-left);*/
    /*padding-right: constant(safe-area-inset-right);*/
    /*padding-right: env(safe-area-inset-right);*/
    /*padding-bottom: constant(safe-area-inset-bottom);*/
    /*padding-bottom: env(safe-area-inset-bottom);*/
  }

  article {
    margin: 0 4%;
  }

  h1 {
    margin: 15px 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #3c3c3c;
    font-size: 20px;
  }
  p {
    text-indent: 2em;
    margin: 12px 0;
    font-size: 13px;
  }
  .bold {
    font-weight: bold;
  }
  .center {
    text-align: center;
  }
  .licence {
    width: 80%;
    margin-left: 18px;
  }
</style>
